p[data-v-09aa0f7a] {
  margin: 0;
}
.jsTips[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  font-size: 14px;
  line-height: 24px;
  color: #5e6d82;
}
.jsStaticData[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
}
.jsStaticData .json-box[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.monaco-container[data-v-09aa0f7a] {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.elsteps[data-v-09aa0f7a] {
  width: 506px;
  padding: 6px 20px;
  background: #fff;
  justify-items: flex-start;
}
.steps[data-v-09aa0f7a] {
  width: 318px;
  padding: 6px 20px;
  background: #fff;
  justify-items: flex-start;
}
.flow-form-main[data-v-09aa0f7a] .main {
  padding: 0 !important;
}
.flow-form-main[data-v-09aa0f7a] .el-tabs__header {
  padding: 0;
  margin-bottom: 0;
}
.flow-form-main[data-v-09aa0f7a] .el-tabs__header .el-tabs__item {
  line-height: 50px;
}
.flow-form-main .config[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: hidden;
}
.flow-form-main .config .tableData[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 350px;
}
.flow-form-main .config .tableData .box[data-v-09aa0f7a] {
  margin-top: 8px;
  border-radius: 4px;
  height: calc(100% - 40px);
  border: 1px solid #dcdfe6;
  overflow: auto;
  overflow-x: hidden;
}
.flow-form-main .config .tableData .box[data-v-09aa0f7a] .el-tree-node__content > .el-tree-node__expand-icon {
  padding: 0;
}
.flow-form-main .config .detail[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 10px;
  margin-top: -10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flow-form-main .config .detail .top-box[data-v-09aa0f7a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flow-form-main .config .detail .top-box .main-box[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 18px;
}
.flow-form-main .config .detail .sql-box[data-v-09aa0f7a] {
  border-top: 1px solid #dcdfe6;
  height: calc(100vh - 258px);
  overflow: auto;
}
.flow-form-main .config .detail .tips[data-v-09aa0f7a] {
  padding: 8px 0;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
}
.flow-form-main .config .detail .tips p[data-v-09aa0f7a] {
  padding: 8px 0 8px 20px;
}
.flow-form-main .config .detail .tips .tips-content[data-v-09aa0f7a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.flow-form-main .config .detail .tips .tips-content .tips-content-item[data-v-09aa0f7a] {
  display: inline-block;
  padding-left: 20px;
  line-height: 30px;
  color: #5e6d82;
  width: 50%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flow-form-main .config .detail .tips .tips-content .tips-content-item span[data-v-09aa0f7a] {
  cursor: pointer;
  padding-right: 2px;
}
.flow-form-main .staticData[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flow-form-main .staticData .json-box[data-v-09aa0f7a] {
  height: calc(100vh - 210px);
}
.flow-form-main .parameterList[data-v-09aa0f7a] {
  padding-left: 110px;
  margin-bottom: 18px;
}
.flow-form-main .parameterList[data-v-09aa0f7a] .el-icon-edit-outline,
.flow-form-main .parameterList[data-v-09aa0f7a] .el-icon-delete {
  font-size: 16px;
}
.flow-form-main .middle-pane[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin-top: 10px;
}
.flow-form-main .middle-pane .right-pane-list[data-v-09aa0f7a] {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;
}
.flow-form-main .middle-pane .right-pane-list .cap[data-v-09aa0f7a] {
  height: 36px;
  line-height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #606266;
  font-size: 14px;
  padding: 0 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flow-form-main .middle-pane .right-pane-list .table-actions[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.flow-form-main .middle-pane .right-pane-list .list[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.flow-form-main .middle-pane .right-pane-list[data-v-09aa0f7a] .el-icon-edit-outline,
.flow-form-main .middle-pane .right-pane-list[data-v-09aa0f7a] .el-icon-delete {
  font-size: 16px;
}
.flow-form-main .middle-pane .right-pane-btn[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.flow-form-main .middle-pane .right-pane-btn .el-button[data-v-09aa0f7a] {
  width: 100%;
}
.flow-form-main .right-pane[data-v-09aa0f7a] {
  width: 350px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: calc(100% + 9px);
  overflow: hidden;
}
.flow-form-main .right-pane .right-pane-list[data-v-09aa0f7a] {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 10px;
  overflow: hidden;
}
.flow-form-main .right-pane .right-pane-list .cap[data-v-09aa0f7a] {
  height: 38px;
  line-height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #606266;
  font-size: 14px;
  padding: 0 10px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flow-form-main .right-pane .right-pane-list .table-actions[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.flow-form-main .right-pane .right-pane-list .list[data-v-09aa0f7a] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.flow-form-main .right-pane .right-pane-list[data-v-09aa0f7a] .el-icon-edit-outline,
.flow-form-main .right-pane .right-pane-list[data-v-09aa0f7a] .el-icon-delete {
  font-size: 16px;
}
.flow-form-main .right-pane .right-pane-btn[data-v-09aa0f7a] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.flow-form-main .right-pane .right-pane-btn .el-button[data-v-09aa0f7a] {
  width: 100%;
}